import React, {useState, createRef} from 'react';
import { Link, NavLink } from 'react-router-dom';
import Auth from "../utils/auth";
import { Navbar, Nav } from 'react-bootstrap';
import SearchForm from './SearchForm';
import useOutsideClick from '../hooks/useOutsideClick';
import ToolTipCustom from './ToolTipCustom';

const AppNavbar = () => {
    const [expanded, setExpanded] = useState(false);
    const ref = createRef();
    const parse_token = localStorage.getItem(`id_token`)

    useOutsideClick(ref, () => {
        setExpanded(false)}
    );

    function showNavigation() {
        if (Auth.loggedIn()) {
            return (
                <Nav>
                    <Nav.Link className="Nav_link" activeClassName="activeRoute" as={NavLink} onClick={() => setExpanded(false)} to={`/profile`}>
                    <ToolTipCustom
                        placement='bottom'
                        tooltipContent='Profile'
                        overlayContent={<i class="fas fa-user-circle"></i>}
                        tooltipVariant='custom'
                        badgeVariant='clear'
                    />
                    </Nav.Link>
                    <Nav.Link className="Nav_link" activeClassName="activeRoute" as={NavLink} to={`/`} onClick={() => Auth.logout()}>Logout</Nav.Link>
                </Nav>
            );
          } else {
            return (
                <Nav>
                  <Nav.Link className="Nav_link" activeClassName="activeRoute" as={NavLink} onClick={() => setExpanded(false)} to={`/login`}>Login</Nav.Link>
                  <Nav.Link className="Nav_link" activeClassName="activeRoute" as={NavLink} onClick={() => setExpanded(false)} to={`/signup`}>Signup</Nav.Link>
                </Nav>
              );
          }
        }

    return (
        <>
            <Navbar fixed="top" collapseOnSelect expand="lg" bg="$color-secondary" variant="dark" expanded={expanded}>
              <Navbar.Brand as={Link} to='/'>
                <a>
                <img  
                    src="https://discovery-journey.s3.us-east-2.amazonaws.com/logo-file-01.png" 
                    width="127"
                    height="37"
                    className="d-inline-block align-top"
                    alt="logo" 
                /> 
                </a>                
              </Navbar.Brand>
                <div className="mx-3">
                {Auth.loggedIn ? <SearchForm /> : ''} 
                </div>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded((prevExpanded) => (prevExpanded = !prevExpanded))} ref={ref} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto"onClick={() => setExpanded(false)}>
                    <Nav.Link className="Nav_link" activeClassName="activeRoute" as={NavLink} to={`/`}>
                        <ToolTipCustom
                            placement='bottom'
                            tooltipContent='Home'
                            overlayContent='Home'
                            // overlayContent={<i class="fas fa-folder-open"></i>}
                            tooltipVariant='custom'
                            badgeVariant='clear'
                        />
                    </Nav.Link>
                    <Nav.Link className="Nav_link" activeClassName="activeRoute" as={NavLink} to={`/recordings`}>
                        <ToolTipCustom
                            placement='bottom'
                            tooltipContent='Recordings'
                            overlayContent={<i class="far fa-play-circle"></i>}
                            tooltipVariant='custom'
                            badgeVariant='clear'
                        />
                    </Nav.Link>
                    <Nav.Link className="Nav_link" activeClassName="activeRoute" as={NavLink} to={`/resource`}>
                    {/* <Nav.Link className="Nav_link" activeClassName="activeRoute" onClick={() => window.location.assign(`https://www.peppercorn.ai/community`)}> */}
                        <ToolTipCustom
                            placement='bottom'
                            tooltipContent='Community'
                            overlayContent='Community'
                            tooltipVariant='custom'
                            badgeVariant='clear'
                        />
                    </Nav.Link> 
                    {Auth.loggedIn() ? 
                        <Nav.Link className="Nav_link" activeClassName="activeRoute" onClick={() => window.open(`https://discovery-quiz-062022.herokuapp.com/login?token=${parse_token}`, "_blank")}>
                        <ToolTipCustom
                            placement='bottom'
                            tooltipContent='Quizzes'
                            overlayContent='Quizzes'
                            // overlayContent={<i class="fas fa-poll-h"></i>}
                            tooltipVariant='custom'
                            badgeVariant='clear'
                        />
                        </Nav.Link> : ''}
                    </Nav>
                    {showNavigation()}
                    </Navbar.Collapse>
                  
            </Navbar>
        </>
    );
}; 

export default AppNavbar;
