const tutorialData = [
    {
         _id: "m0",
         tutorialNumber : 0,
         tutorialTitle : "Using Discovery",
         tutorialOverview : "You can always come back to the tutorial button on the home page or go to Module 600: Discovery Appendix and then click on Section Number 98: ... Discovery Tutorial to watch a video tutorial.",
         tutorialCategory : "",
         tutorialPoster : "thumbnail-module-6.jpg",
         tutorialLink :"https://bit.ly/062022Tutorial",
         tutorialVideo :"",
   },
     {
         _id: "m1",
         tutorialNumber : 1,
         tutorialTitle : "Table of Contents",
         tutorialOverview : "The Table of Contents gives you the structure you need to finish Discovery and move on to What's Next! Make sure to follow the program as listed.  You can easily get back to the Table of Contents every time you log in if you bookmark it.",
         tutorialCategory : "",
         tutorialPoster : "thumbnail-module-2.jpg",
         tutorialLink :"https://bit.ly/062022TOC",
         tutorialVideo :"",
     },
   {
         _id: "m2",
         tutorialNumber : 2,
         tutorialTitle : "Modules",
         tutorialOverview: "The Modules give you the structure and direction you need to get to - What's Next! - Follow the order in which the Modules are listed in the application. You can move through each lesson by clicking previous and next. The application Discovery works best on a Chrome browser. You can also get to any part of the program by clicking on the + sign in the top left corner of the side table of contents.",
         tutorialCategory : "",
         tutorialPoster : "thumbnail-module-1.jpg",
         tutorialLink :"https://discovery-journey-062022.herokuapp.com/",
         tutorialVideo :"",
     },
  
     {
         _id: "m3",
         tutorialNumber : 3,
         tutorialTitle : "Quizzes",
         tutorialOverview: "The Quizzes app is where all of Peppercorn Discovery's exercises are kept. When you log in to Discovery, always click on Quizzes so that you are automatically logged in when you do the exercises. You can also use this link to go back to exercises you've already done and download the results for each. Your Discovery exercises (quizzes) will open in a new window. Once you finish an exercise (quiz), go back to the page you were on before to move on.",
         tutorialCategory : "",
         tutorialPoster : "thumbnail-module-5.jpg",
         tutorialLink: "https://discovery-quiz-012022.herokuapp.com/login?token=${parse_token}",
         tutorialVideo :"",
     },
   {
         _id: "m4",
         tutorialNumber : 4,
         tutorialTitle : "Community",
         tutorialOverview: "Make sure to sign up for the Peppercorn Community and talk with your peers. The Peppercorn Community is a great way to meet new people and get the latest resources, articles, and insights while looking for What's Next! You'll need to sign up for a separate login to access the Peppercorn Community.",
         tutorialCategory : "",
         tutorialPoster : "thumbnail-module-3.jpg",
         tutorialLink :"https://bit.ly/PCCOMMUNITY",
         tutorialVideo :"",
     },
  {
         _id: "m5",
         tutorialNumber : 5,
         tutorialTitle : "Programs and Coaching",
         tutorialOverview: "As part of your journey, you will be able to take part in special programs and events through the Discovery process. Please follow the link to find out about and sign up for the latest programs, coaching sessions, and events. Also, if you want information about 1-on-1 coaching, send an email to coach@peppercorn.ai",
         tutorialCategory : "",
         tutorialPoster : "thumbnail-module-4.jpg",
         tutorialLink : "https://bit.ly/PDPPWEB",
         tutorialVideo :"",
     },
      {
         _id: "m6",
         tutorialNumber : 6,
         tutorialTitle : "Recordings",
         tutorialOverview : "All video recordings in the Peppercorn Discovery will be in the Modules and the Recordings link.",
         tutorialCategory : "",
         tutorialPoster : "thumbnail-module-6.jpg",
         tutorialLink :"https://discovery-journey-012022.herokuapp.com/recordings",
         tutorialVideo :"",
     }
      ];
  
  export default tutorialData;