import React, { useState } from 'react';
// redirect to another route, retrieves username from the URL
import { Redirect, useParams, Link } from 'react-router-dom';
import { Col, Row, Button } from 'react-bootstrap';
import Completed from '../components/Completed';
import { useQuery } from '@apollo/react-hooks';
import { GET_USER, QUERY_ME } from '../utils/queries';
import Auth from '../utils/auth';

//import FriendList from '../components/FriendList';
/* 45 -53
 const handleClick = async () => {
    try {
      await addFriend({
        variables: { id: user._id }
      });
    } catch (e) {
      console.error(e);
    }
  };
          {userParam && (
          <button className="btn ml-auto" onClick={handleClick}>
            Add Friend
          </button>
        )}
  */
/* 64 - 70
        <div className="col-12 col-lg-3 mb-3">
          <FriendList
            username={user.username}
            friendCount={user.friendCount}
            friends={user.friends}
          />
        </div>
   line 74
         <div className="mb-3">{!userParam && <ThoughtForm />}</div>     
*/

//import { ADD_FRIEND } from '../utils/mutations'; 


const Profile = () => {
  const [buttonSubmit, setButtonSubmit] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { username: userParam } = useParams();
  const loggedIn = Auth.loggedIn();
  const [formState, setFormState] = useState({ amount: ''});
  // useParams Hook retrieves the username from the URL which is then passed to the useQuery Hook
  // if no userParam from the URL bar, use value to run QUERY_ME( ex. visit /profile while logged in)
  const { loading, data } = useQuery(userParam ? GET_USER : QUERY_ME, {
    variables: { username: userParam }
  });

  const donateButton = () => {
    setSubmitting(true);
    window.location.assign("https://discovery-payment-062022.herokuapp.com");
  }

  // when QUERY_ME, return data in the me property, QUERY_USER, return data.user
  const user = data?.me || data?.user || {};
  //***const [addFriend] = useMutation(ADD_FRIEND);

  // redirect to personal profile page if username is the logged-in user's
  // check to see if username stored in the JSON Web Token is the same as the userParam 
  if (loggedIn && Auth.getProfile().data.username === userParam) {
    return <Redirect to="/profile" />;
  }

  if (loading) {
    return <div>Loading...</div>;
  }


  return (
    <div className="container">
    <div className="mx-auto">
      {loggedIn ? (
        <>
      <div className="text-center">
        <h2 className="bg-light text-primary p-3">
          {userParam ? `${user.username}'s` : 'Your'}  profile page
        </h2>
        <hr />
        
      </div>

      <div className="flex-row justify-space-between my-2">
      <h3 className="my-2 mx-2">Here are {user.username}'s completed modules</h3>
        <Row>
        <Col sm={8}>
          <Completed completed={user.completed}/>
        </Col>
        <Col sm={4}>
        <Button 
            className='btn d-block w-75 mx-auto' 
            type='link'
            onClick={donateButton}
            variant='success'
            disabled = {buttonSubmit}
        >{isSubmitting && <div><p>This will take a minute</p> <span className="spinner-border spinner-border-sm mr-1"></span></div>}
          {buttonSubmit ? 'Thank you!! ✈ ' : 'Consider Donating'}
        </Button>
        </Col>
        </Row>
      </div>
      </>
        ): (<>
           {localStorage.setItem('referring_url', window.location.href)}
          <hr /><h4 className="text-center">You need to {<Link to={`/login`}>Login</Link>} before you can access a profile page</h4>
          </>
          )}
    </div>
    </div>
  );
};

export default Profile;
